import React, { useEffect } from 'react'
import './App.css'
import { Router } from 'react-router-dom'
import Mensagens from './_components/mensagens.component'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from './theme'
import 'react-perfect-scrollbar/dist/css/styles.css'
import './assets/scss/index.scss'
import Routes from './Routes'
import { history } from './_helpers'
import { ConfirmProvider } from 'material-ui-confirm'
import { authActions } from './_actions'
import { useDispatch } from 'react-redux'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { DialogDownloadApp, DialogLogin } from 'components'
import 'react-credit-cards/lib/styles.scss'
//importação para datas e time
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import brLocale from 'date-fns/locale/pt-BR'
import ReactGA from 'react-ga'

ReactGA.initialize('UA-173583129-1')
ReactGA.pageview(window.location.pathname + window.location.search)

const App = () => {
  const dispatch = useDispatch()
  const queryClient = new QueryClient()

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (token) {
      dispatch(authActions.refresh())
    }
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocale}>
        <ThemeProvider theme={theme}>
          <ConfirmProvider>
            <div className="App">
              <Mensagens />
              <DialogLogin />
              <DialogDownloadApp />
              <Router history={history}>
                <Routes />
              </Router>
            </div>
          </ConfirmProvider>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}
export default App
