import React from 'react'
import {
  Box,
  Typography,
  Grid,
  Button,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core'

//? Own imports
import { Header } from 'components'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: { padding: theme.spacing(2) },
    textAlign: 'left'
  },
  container: {
    margin: '0 auto'
  },
  section: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    boxShadow: 'rgb(0 0 0 / 8%) 0px 2px 16px',
    padding: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  sectionTitle: {
    color: theme.palette.text.primary,
    fontSize: '1.5rem',
    marginBottom: theme.spacing(3),
    borderBottom: `2px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(2),
    fontWeight: 500
  },
  sectionContent: {
    color: theme.palette.text.secondary,
    lineHeight: 1.8
  },
  list: {
    paddingLeft: theme.spacing(4),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  listItem: {
    marginBottom: theme.spacing(1)
  },
  contactInfo: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    borderLeft: `4px solid ${theme.palette.primary.main}`
  }
}))

const Help = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Box mb={4} mt={2}>
          <Header
            title="Central de Ajuda"
            subtitle="Dúvidas frequentes e suporte"
          />
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box className={classes.section}>
              <Typography variant="h5" className={classes.sectionTitle}>
                Dúvidas sobre o Evento
              </Typography>
              <Box className={classes.sectionContent}>
                <Typography paragraph>
                  Se você possui dúvidas relacionadas ao evento específico,
                  como:
                </Typography>
                <ul className={classes.list}>
                  <li className={classes.listItem}>
                    <Typography>
                      Informações sobre o local e horário do evento
                    </Typography>
                  </li>
                  <li className={classes.listItem}>
                    <Typography>
                      Regras de entrada e política de idade
                    </Typography>
                  </li>
                  <li className={classes.listItem}>
                    <Typography>Cancelamento ou adiamento do evento</Typography>
                  </li>
                  <li className={classes.listItem}>
                    <Typography>
                      Informações sobre estacionamento ou transporte
                    </Typography>
                  </li>
                  <li className={classes.listItem}>
                    <Typography>
                      Permissão para entrada de alimentos ou bebidas
                    </Typography>
                  </li>
                  <li className={classes.listItem}>
                    <Typography>
                      Outras questões relacionadas à organização do evento
                    </Typography>
                  </li>
                </ul>
                <Box className={classes.contactInfo}>
                  <Typography variant="subtitle1" gutterBottom>
                    <strong>
                      Entre em contato diretamente com o organizador.
                    </strong>
                  </Typography>
                  <Typography>
                    As informações de contato geralmente estão disponíveis na
                    página de descrição do evento.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.section}>
              <Typography variant="h5" className={classes.sectionTitle}>
                Suporte da Plataforma
              </Typography>
              <Box className={classes.sectionContent}>
                <Typography paragraph>
                  Se você está enfrentando problemas técnicos com nossa
                  plataforma, como:
                </Typography>
                <ul className={classes.list}>
                  <li className={classes.listItem}>
                    <Typography>Dificuldades ao realizar o cadastro</Typography>
                  </li>
                  <li className={classes.listItem}>
                    <Typography>
                      Problemas na compra ou pagamento de ingressos
                    </Typography>
                  </li>
                  <li className={classes.listItem}>
                    <Typography>
                      Funcionalidades do site que não estão operando
                      corretamente
                    </Typography>
                  </li>
                  <li className={classes.listItem}>
                    <Typography>Erros na sua conta ou acesso</Typography>
                  </li>
                  <li className={classes.listItem}>
                    <Typography>
                      Outras questões relacionadas ao uso da plataforma
                    </Typography>
                  </li>
                </ul>

                <Box className={classes.contactInfo}>
                  <Typography variant="subtitle1" gutterBottom>
                    <strong>
                      Entre em contato com nossa equipe de suporte:
                    </strong>
                  </Typography>
                  <Typography gutterBottom>
                    Nossa equipe está disponível para ajudar você! Entre em
                    contato conosco por e-mail ou WhatsApp:
                  </Typography>
                  <Typography gutterBottom>
                    Email:{' '}
                    <a
                      href="mailto:contato@corujasapp.com"
                      style={{ color: 'inherit', textDecoration: 'underline' }}
                    >
                      contato@corujasapp.com
                    </a>
                  </Typography>
                  <Box mt={2}>
                    <Button
                      fullWidth={isMobile}
                      href="https://api.whatsapp.com/send?phone=5551995482574&text=Ol%C3%A1!%20%F0%9F%98%80"
                      variant="contained"
                      color="primary"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Fale conosco via WhatsApp
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          {/* <Grid item xs={12}>
            <Box className={classes.section}>
              <Typography variant="h5" className={classes.sectionTitle}>
                Perguntas Frequentes
              </Typography>
              <Box className={classes.sectionContent}>
                <Typography variant="h6" gutterBottom>
                  Como recupero minha senha?
                </Typography>
                <Typography paragraph>
                  Clique em "Esqueci minha senha" na tela de login e siga as
                  instruções enviadas ao seu email.
                </Typography>

                <Typography variant="h6" gutterBottom>
                  Posso transferir meu ingresso para outra pessoa?
                </Typography>
                <Typography paragraph>
                  Verifique as regras do evento específico, pois cada
                  organizador determina suas próprias políticas de
                  transferência.
                </Typography>

                <Typography variant="h6" gutterBottom>
                  Como solicitar reembolso?
                </Typography>
                <Typography paragraph>
                  Entre em contato com o organizador do evento para reembolsos.
                  Nossa plataforma segue as políticas estabelecidas pelos
                  organizadores.
                </Typography>
              </Box>
            </Box>
          </Grid> */}
        </Grid>
      </Box>
    </Box>
  )
}

export default Help
