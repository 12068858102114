import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import {
  Typography,
  Link,
  Container,
  Grid,
  Box,
  IconButton,
  Hidden,
  useTheme,
  useMediaQuery
} from '@material-ui/core'
import Favorite from '@material-ui/icons/Favorite'
import InstagramIcon from '@material-ui/icons/Instagram'
import FacebookIcon from '@material-ui/icons/Facebook'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import { history } from '_helpers'
import { useDispatch, useSelector } from 'react-redux'
import { categoriaAction } from '_actions'

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none'
    }
  },
  root: {
    padding: theme.spacing(4),
    backgroundColor: '#030518'
  },
  icon: {
    width: '18px',
    height: '18px',
    position: 'relative',
    top: '3px'
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4)
    }
  },
  imageIcon: {
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(2)
    },
    width: 'auto',
    height: '60px'
  },
  imageGooglePlay: {
    width: '160px',
    height: 'auto'
  },
  imagePickBus: {
    maxWidth: '100px',
    maxHeight: '30px',
    height: 'auto',
    margin: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0.5)
    }
  },
  imageAppStore: {
    width: '145px',
    height: 'auto'
  },
  socialIcon: {
    color: '#FAFAFA',
    margin: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0)
    }
  },
  textWhite: {
    color: '#FAFAFA'
  }
}))

const FooterMain = (props) => {
  const { className, ...rest } = props
  const classes = useStyles()
  const categoria = useSelector((state) => state.categoria.categoria)
  const dispatch = useDispatch()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  useEffect(() => {
    if (categoria.length < 1) {
      dispatch(categoriaAction.getCategoria())
    }
  }, [])

  const mountCategorias = (categoria) => {
    var categoriasArray = []
    if (!!categoria && categoria.length > 0) {
      categoria.map((item) =>
        categoriasArray.push({
          title:
            item.descricao.charAt(0).toUpperCase() +
            item.descricao.slice(1).toLowerCase(),
          href: `/search?cat=${item.descricao.toLowerCase()}&catId=${item.id}`
        })
      )
      return categoriasArray
    } else {
      return []
    }
  }
  const footers = [
    {
      title: 'Links úteis',
      description: [
        {
          title: 'Portal do organizador',
          href: 'https://organizador.corujasapp.com/'
        },
        { title: 'Buscar eventos', href: '/search' },
        { title: 'Meus ingressos', href: '/approot/perfil/tickets' },
        { title: 'Meus pedidos', href: '/meus-pedidos' },
        {
          title: 'Validador de ingressos (Android)',
          href: 'https://play.google.com/store/apps/details?id=com.quintos.corujas.validador'
        },
        {
          title: 'Validador de ingressos (iOS)',
          href: 'https://apps.apple.com/us/app/corujas-validador-de-ingressos/id1598654541'
        }
      ]
    },
    {
      title: 'Categorias',
      description: mountCategorias(categoria)
    },
    {
      title: 'Ajuda',
      description: [
        {
          title: 'Fale com a gente',
          href: '/ajuda'
        },
        {
          title: 'Dúvidas frequentes',
          href: '/ajuda'
        }
      ]
    },
    {
      title: 'Legal',

      description: [
        {
          title: 'Política de Privacidade',
          href: 'https://corujastermos.s3.us-east-2.amazonaws.com/politica_privacidade.pdf'
        },
        {
          title: 'Termos de uso',
          href: 'https://corujastermos.s3.us-east-2.amazonaws.com/termos_uso.pdf'
        }
      ]
    }
  ]
  const itemClick = (href) => {
    if (href.includes('https')) {
      var win = window.open(href, '_blank')
      win.focus()
    } else if (href.includes('mailto')) {
      window.open(href)
    } else {
      window.scrollTo(0, 0)
      history.push(href)
    }
  }

  function Copyright() {
    return (
      <Typography className={classes.textWhite} variant="body2">
        &copy; {1900 + new Date().getYear()}, feito com{' '}
        <Favorite className={classes.icon} size="small" color="primary" /> por{' '}
        Corujas.
      </Typography>
    )
  }

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid container spacing={4} justify="center">
        {/* <Grid item xs={6} sm={4}>
          <Typography variant="h6" className={classes.textWhite} gutterBottom>
            <strong>Parceiros</strong>
          </Typography>
          <a href="https://pickbus.com.br/" rel="noreferrer" target="_blank">
            <img
              className={classes.imagePickBus}
              src="https://imagens.corujatickets.com/logo_nome.4d1faa3f.png"
            />
          </a>
        </Grid> */}
        <Grid item xs={6} sm={4}>
          <Typography variant="h6" className={classes.textWhite} gutterBottom>
            <strong>Redes sociais</strong>
          </Typography>
          <IconButton
            size="small"
            href="https://www.instagram.com/corujasapp/"
            rel="noreferrer"
            target="_blank"
            aria-label="Instagram"
          >
            <InstagramIcon className={classes.socialIcon} />
          </IconButton>
          <IconButton
            size="small"
            href="https://www.facebook.com/appcorujas"
            rel="noreferrer"
            target="_blank"
            aria-label="Facebook"
          >
            <FacebookIcon className={classes.socialIcon} />
          </IconButton>
          {/* <IconButton
            size="small"
            href="https://api.whatsapp.com/send?phone=5551995482574&text=Ol%C3%A1!%20%F0%9F%98%80"
            rel="noreferrer"
            target="_blank"
            aria-label="WhatsApp"
          >
            <WhatsAppIcon className={classes.socialIcon} />
          </IconButton> */}
          {/* <IconButton
            size="small"
            href=""
            rel="noreferrer"
            target="_blank"
            aria-label="LinkedIn"
          >
            <LinkedInIcon className={classes.socialIcon} />
          </IconButton> */}
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant="h6" className={classes.textWhite}>
            <strong>Baixe nosso app!</strong>
          </Typography>
          <Grid
            container
            alignContent="center"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item sm={12} md={6} lg={5}>
              <a
                href="https://play.google.com/store/apps/details?id=com.quintos.corujas"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  className={classes.imageGooglePlay}
                  src="https://corujas.s3.us-east-2.amazonaws.com/images/general/google-play-badge+(1).svg"
                />
              </a>
            </Grid>
            <Grid item sm={12} md={6} lg={4}>
              <a
                href="https://apps.apple.com/us/app/corujas/id1593667736"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  className={classes.imageAppStore}
                  src="https://corujas.s3.us-east-2.amazonaws.com/images/general/Download_on_the_App_Store_Badge_PTBR_RGB_blk_092917.svg"
                />
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* FooterMain */}
      <Container maxWidth="lg" component="footer" className={classes.footer}>
        <Grid container spacing={4} justify="space-evenly">
          {footers.map((footer, index) =>
            index === 2 ? (
              <>
                <Hidden xsDown>
                  <Grid item xs={12} sm={4}>
                    <Typography
                      align="center"
                      variant="h5"
                      color="primary"
                      gutterBottom
                    >
                      <strong>O que é o Corujas?</strong>
                    </Typography>
                    <a
                      href="https://sejaparceiro.corujasapp.com"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img
                        className={classes.imageIcon}
                        src={require('../../img/img-login.svg')}
                      />
                    </a>
                  </Grid>
                </Hidden>
                <Grid item xs={6} sm={2} key={footer.title}>
                  <Typography
                    align="left"
                    variant="h6"
                    className={classes.textWhite}
                    gutterBottom
                  >
                    <strong>{footer.title}</strong>
                  </Typography>
                  <ul>
                    {footer.description.map((item) => (
                      <li style={{ textAlign: 'left' }} key={item.title}>
                        <Link
                          align="left"
                          component="button"
                          onClick={() => {
                            itemClick(item.href)
                          }}
                          variant="subtitle2"
                          className={classes.textWhite}
                        >
                          {item.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </Grid>
              </>
            ) : (
              <Grid item xs={6} sm={2} key={footer.title}>
                <Typography
                  align="left"
                  variant="h6"
                  className={classes.textWhite}
                  gutterBottom
                >
                  <strong>{footer.title}</strong>
                </Typography>
                <ul>
                  {footer.description.map((item) => (
                    <li style={{ textAlign: 'left' }} key={item.title}>
                      <Link
                        align="left"
                        component="button"
                        onClick={() => {
                          itemClick(item.href)
                        }}
                        variant="subtitle2"
                        className={classes.textWhite}
                      >
                        {item.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </Grid>
            )
          )}
        </Grid>
        <Box style={{ paddingTop: 25 }} mt={5}>
          <Copyright />
        </Box>
      </Container>
      {/* End footer */}
    </div>
  )
}

FooterMain.propTypes = {
  className: PropTypes.string
}

export default FooterMain
