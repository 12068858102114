import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { RouteWithLayout } from './components'
import { PrivateRouteWithLayout } from './components'
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts'

import {
  Home as HomeView,
  Search as SearchView,
  Evento as EventoView,
  Login as LoginView,
  RequestPasswordReset as RequestPasswordResetView,
  PasswordReset as PasswordResetView,
  EmailVerification as EmailVerificationView,
  OrderPayment as OrderPaymentView,
  Cadastro as CadastroView,
  Error404 as Error404View,
  PhotoAlbum as PhotoAlbumView,
  EventoNotFound as EventoNotFoundView,
  MyAccount as MyAccountView,
  EventosEstabelecimento as EventosEstabelecimentoView,
  MyTickets as MyTicketsView,
  MyOrders as MyOrdersView,
  MemberList as MemberListView,
  EstablishmentAssociationPlans as EstablishmentAssociationPlansView,
  PlanPurchaseDetail as PlanPurchaseDetailView,
  MembershipSettings as MembershipSettingsView,
  PlanPurchaseConfirmation as PlanPurchaseConfirmationView,
  CheckIn as CheckInView,
  CheckInDetail as CheckInDetailView,
  Help as HelpView
} from './views'
import { TermosDeUso as TermosDeUsoView } from './_components/termosDeUso.component'
import { PrivacyPolicy as PrivacyPolicyView } from './_components/privacyPolicy'

const Routes = () => {
  return (
    <Switch>
      <RouteWithLayout
        component={HomeView}
        exact
        layout={MinimalLayout}
        path="/home"
      />
      <RouteWithLayout
        component={HomeView}
        exact
        layout={MinimalLayout}
        path="/"
      />
      <RouteWithLayout
        component={EventoView}
        exact
        layout={MinimalLayout}
        path="/event-details/:id"
      />
      <PrivateRouteWithLayout
        component={CheckInView}
        exact
        layout={MinimalLayout}
        path="/check-in/:eventId"
      />
      <PrivateRouteWithLayout
        component={CheckInDetailView}
        exact
        layout={MinimalLayout}
        path="/check-in-details/:eventId"
      />
      <RouteWithLayout
        component={EventosEstabelecimentoView}
        exact
        layout={MinimalLayout}
        path="/estabelecimento-profile/:id"
      />
      <Route component={LoginView} exact path="/login" />
      <Route component={PasswordResetView} exact path="/password-reset" />
      <Route
        component={RequestPasswordResetView}
        exact
        path="/request-password-reset"
      />
      <Route
        component={EmailVerificationView}
        exact
        path="/email-verification"
      />
      <RouteWithLayout
        component={Error404View}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <RouteWithLayout
        component={PhotoAlbumView}
        exact
        layout={MinimalLayout}
        path="/event-photo-album/:albumId"
      />
      <RouteWithLayout
        component={PhotoAlbumView}
        exact
        layout={MinimalLayout}
        path="/event-photo-album/:albumId/:photoId"
      />
      <RouteWithLayout
        component={EventoNotFoundView}
        exact
        layout={MinimalLayout}
        path="/evento-nao-encontrado"
      />
      <RouteWithLayout
        exact
        path="/search"
        layout={MinimalLayout}
        component={SearchView}
      />
      <PrivateRouteWithLayout
        component={MyAccountView}
        exact
        layout={MainLayout}
        path="/meus-dados"
      />
      <PrivateRouteWithLayout
        component={MyAccountView}
        exact
        layout={MainLayout}
        path="/meus-dados/:tab"
      />
      <PrivateRouteWithLayout
        exact
        path="/approot/perfil/tickets/"
        layout={MainLayout}
        component={MyTicketsView}
      />
      <PrivateRouteWithLayout
        component={MyTicketsView}
        exact
        layout={MainLayout}
        path="/approot/perfil/tickets/:tab"
      />
      <PrivateRouteWithLayout
        exact
        path="/meus-pedidos/"
        layout={MainLayout}
        component={MyOrdersView}
      />
      <PrivateRouteWithLayout
        exact
        path="/member-list/"
        layout={MainLayout}
        component={MemberListView}
      />
      <RouteWithLayout
        exact
        path="/establishment-association-plans/:establishmentId"
        layout={MinimalLayout}
        component={EstablishmentAssociationPlansView}
      />
      <PrivateRouteWithLayout
        exact
        path="/plan-purchase-details/:planValueId"
        layout={MinimalLayout}
        component={PlanPurchaseDetailView}
      />
      <PrivateRouteWithLayout
        exact
        path="/plan-purchase-confirmation/:subscriptionId"
        layout={MinimalLayout}
        component={PlanPurchaseConfirmationView}
      />
      <PrivateRouteWithLayout
        exact
        path="/membership-settings/:tab/:membershipId"
        layout={MainLayout}
        component={MembershipSettingsView}
      />
      <PrivateRouteWithLayout
        exact
        path="/ingressos/checkout/:eventoId"
        layout={MinimalLayout}
        component={OrderPaymentView}
      />
      <PrivateRouteWithLayout
        exact
        path="/pedido/:pedidoId"
        layout={MinimalLayout}
        component={OrderPaymentView}
      />
      <RouteWithLayout
        exact
        path="/ajuda"
        layout={MinimalLayout}
        component={HelpView}
      />
      <PrivateRouteWithLayout
        exact
        path="/pedido/:pedidoId/payment-success"
        layout={MinimalLayout}
        component={OrderPaymentView}
      />
      <RouteWithLayout
        exact
        path="/termos"
        layout={MinimalLayout}
        component={TermosDeUsoView}
      />
      <RouteWithLayout
        exact
        path="/privacy-policy"
        layout={MinimalLayout}
        component={PrivacyPolicyView}
      />
      <Route exact path="/cadastrar" component={CadastroView} />
      <RouteWithLayout
        component={EventosEstabelecimentoView}
        exact
        layout={MinimalLayout}
        path="/:id"
      />
      {/* Direciona qualquer rota inválida * para o componente "Home" */}
      <Redirect from="/signin-facebook" to="/signin-facebook" />
      <Redirect to="/not-found" />
    </Switch>
  )
}

export default Routes
